import React from 'react';
import { Trans } from 'react-i18next';
import {
  DxLink,
  DxText,
  DxSpacer,
  DxContainer,
} from '@dvag/design-system-react';

import style from './Footer.module.css';

const Footer: React.FC = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <DxContainer className={style.footer_container} color="gray-66">
      <DxSpacer className={style.spacer} size="16v" />
      <DxText type="its" color="white" className={style.copyright}>
        <Trans i18nKey="footer.copyright" values={{ currentYear }} />
      </DxText>
      <DxSpacer size="8v" />
      <div className={style.links}>
        <DxLink
          href="https://www.vbwelt.dvag/datenschutzhinweise-online-dienste"
          id="privacy-policy-link"
          target="_blank"
        >
          <DxText type="it" color="white">
            <Trans i18nKey="footer.link1" />
          </DxText>
        </DxLink>
        <DxSpacer size="16h" />
        <DxLink
          href="https://www.dvag.de/dvag/impressum.html"
          id="imprint-link"
          target="_blank"
        >
          <DxText type="it" color="white" className={style.terms_of_use}>
            <Trans i18nKey="footer.link2" />
          </DxText>
        </DxLink>
      </div>
      <DxSpacer className={style.spacer} size="4v" />
    </DxContainer>
  );
};

export default Footer;
